import React, { useState, useEffect, useContext } from 'react';
import comm from '../../common';
import axios from 'axios';
import { Button, Hidden, IconButton, Snackbar } from '@material-ui/core';
import Select from "react-select";
import { autoSendDays, clubOption, jiguOption, jiyeokOption } from './selectItems';
import { userDto } from '../mobile/memberList';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { Receiver, SmsDto } from '../common/dto';
import { useHistory } from 'react-router-dom';
import GroupContext from '../../context/groupContext';
import { BasicBlueButton } from '../common/commonUI';

const NoticePosting = () => {
    const history: any = useHistory();
    const groupValue = useContext(GroupContext);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [checkFixLevel, setCheckFixLeve] = useState(false);

    useEffect(() => {

    }, []);

    const handleFileInputChange = () => {
        console.log(" @@@@ ");
    }

    const handleFixLeve = () => {
        setCheckFixLeve(!checkFixLevel);
    }

    const uploadNotice = () => {
        let uploadData = {
            fixedLevel: checkFixLevel ? 1 : 0,
            title: title,
            content: content, 
            imageUrl:'',
            jiguId:groupValue.jiguId,
        }
        axios.post(comm.SERVER_URL+"/notice", uploadData).then(res =>{

        });
    }



    return ( <div className='grid place-items-center'>
                <div className='mt-12 font-bold text-2xl'>공지 등록</div>
                <div className='flex flex-col mt-4'>
                    <div className='flex flex-row items-center mt-4'>
                        <span>제목: </span>
                        <input type='text' className='input w-116 h-12 ml-4' onChange={(ev) => {setTitle(ev.target.value)}} />
                    </div>
                    <div className='flex flex-col items-start mt-4'>
                        <span>내용: </span>
                        <textarea className='w-132 h-52 border p-2 border-gray-400' onChange={(ev) => {setContent(ev.target.value)}} />
                    </div>
                    <div className='flex flex-row items-center mt-4'>
                        <span className="w-16">파일 첨부</span>
                        <label className='w-22 ml-4 flex items-center justify-center border-gray-300 border-2 rounded-lg'>파일 선택
                            <input type="file" name="inputPic" id="inputPic" accept="*.*" className='hidden' onChange={handleFileInputChange} />
                        </label>
                    </div>
                    <div className='flex flex-row h-16 mt-4' id="file-area">

                    </div>
                    <div className='flex flex-row'>
                        <BasicBlueButton btnText='공지 올리기' width='w-24' height='h-10' font='text-lg' margin='mr-6' handleClick={uploadNotice} />
                        <label className='flex items-center ml-2'>
                            <input type='checkbox' defaultChecked={false} className='w-5 h-5 mr-1 text-sm' onChange={handleFixLeve} />상단 고정
                        </label>
                    </div>
                </div>
            </div>)
}

export default NoticePosting;
