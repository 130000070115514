import React from 'react';
import defalutMaleImg from '../../images/defalut_male_img.jpeg';
import defalutFemaleImg from '../../images/defalut_female_img.jpeg';
import logo from '../../images/lions_logo.png';
import { Backdrop, Fade, Modal, Theme, createStyles, makeStyles } from '@material-ui/core';
import { userDto } from '../mobile/memberList';
import { userNclub } from '../mobile/jiguMemberList';

export interface selectOption {
    value:number;
    label:string;
}

export const getMobilePhoneFormText = (phoneNum : string) => {
    if(phoneNum.length === 11){
        return phoneNum.substr(0,3)+"-"+phoneNum.substr(3,4)+"-"+phoneNum.substr(7,4);
    }else{
        return phoneNum;
    }
}

// export const getMemberImg = async (imgUrl?:string, gender?:string) => {
//     let checkUrl = '';
//     if(imgUrl){
//         checkUrl = `https://clublions.s3.ap-northeast-2.amazonaws.com/${imgUrl}`
//         const response = await fetch(checkUrl)
//         if(response.ok){
//             return checkUrl;
//         }else{
//             return checkUrl.normalize('NFC');
//         }
//     }else{
//         if(gender == "남자"){
//             return defalutMaleImg;
//         }else if(gender == "여자"){
//             return defalutFemaleImg;
//         }else{
//             return logo;
//         }
//     }
// }

export const getMemberImg = (member:userDto) => {
    let encodedImgUrl = member.imgName; 
    console.log("encodedImgUrl >> ", member.imgName);
    if(encodedImgUrl){
        return `https://clublions.s3.ap-northeast-2.amazonaws.com/${encodedImgUrl}`
    }else{
        if(member.gender == "남자"){
            return defalutMaleImg;
        }else if(member.gender == "여자"){
            return defalutFemaleImg;
        }else{
            return logo;
        }
    }
}

export const checkEncodeType = (str:String) => {
    if(str == str.normalize('NFC')){
        return 'NFC';
    }else if(str == str.normalize('NFD')){
        return 'NFD';
    }else{
        return 'NONE';
    }
}


export const checkImage = async (url:string) => {
    try {

        console.log("url #### ", url);
        let response = await fetch(url, { method: 'HEAD' , mode:'no-cors' });
        console.log("response >> ", response);
        return response.ok;
    } catch (error) {
        return false;
    }
}

export const getMemberImgAuto = (member:userDto) => {
    let encodedImgUrl = "" 
    if(member.imgName){
        // encodedImgUrl = member.imgName.normalize('NFC');
        return `https://clublions.s3.ap-northeast-2.amazonaws.com/${member.imgName}`;
        // let finalUrl = `https://clublions.s3.ap-northeast-2.amazonaws.com/${encodedImgUrl}`;
        // try {
        //     let response = await fetch(finalUrl, { method: 'HEAD' });
        //     return finalUrl
        // } catch (error) {
        //     encodedImgUrl = member.imgName.normalize('NFD');
        //     return `https://clublions.s3.ap-northeast-2.amazonaws.com/${encodedImgUrl}`;
             
        // }

        // checkImage(finalUrl).then(isValid => {
        //     if(isValid){
        //         return 
        //     }
        // })
        // encodedImgUrl = encodeURIComponent(encodedImgUrl);
    }else{
        if(member.gender == "남자"){
            return defalutMaleImg;
        }else if(member.gender == "여자"){
            return defalutFemaleImg;
        }else{
            return logo;
        }
    }

}

export const getMemberImgWithOption = (member:userDto, option:string) => {
    let encodedImgUrl = "" 
    // console.log("member belongToJigu >> ", member.belongToJigu);
    if(member.belongToJigu == 2){
        encodedImgUrl = member.imgName ? member.imgName: '';
    }else if(member.belongToJigu == 3){
        if(option == 'NFC'){
            encodedImgUrl = member.imgName ? member.imgName.normalize('NFC') : '';
            
        }else if(option == 'NFD'){
            encodedImgUrl = member.imgName ? member.imgName.normalize('NFD') : '';
            
        }else if(option == 'NFKD'){
            encodedImgUrl = member.imgName ? member.imgName.normalize('NFKD') : '';
            
        }else if(option == 'NFKC'){
            encodedImgUrl = member.imgName ? member.imgName.normalize('NFKC') : '';

        }
    }
    // let encodedImgUrl = imgUrl?.normalize('NFD') 
    if(encodedImgUrl){
        return `https://clublions.s3.ap-northeast-2.amazonaws.com/${encodedImgUrl}`
    }else{
        if(member.gender == "남자"){
            return defalutMaleImg;
        }else if(member.gender == "여자"){
            return defalutFemaleImg;
        }else{
            return logo;
        }
    }
}

export const getMemberImgWithOption2 = (member:userNclub, option:string) => {
    let encodedImgUrl = "" 
    
    if(member){
        if(option == 'NFC'){
            encodedImgUrl = member.imgName ? member.imgName.normalize('NFC') : '';    
        }else if(option == 'NFD'){
            encodedImgUrl = member.imgName ? member.imgName.normalize('NFD') : '';
        }else if(option == 'NFKD'){
            encodedImgUrl = member.imgName ? member.imgName.normalize('NFKD') : '';
        }else if(option == 'NFKC'){
            encodedImgUrl = member.imgName ? member.imgName.normalize('NFKC') : '';
        }else{
            encodedImgUrl = member.imgName ? member.imgName : "";
        }
    }else{
        return logo
    }

    if(encodedImgUrl){
        return `https://clublions.s3.ap-northeast-2.amazonaws.com/${encodedImgUrl}`
    }else{
        if(member.gender == "남자"){
            return defalutMaleImg;
        }else if(member.gender == "여자"){
            return defalutFemaleImg;
        }else{
            return logo;
        }
    }
}

export const getMemberImgSimple = (jigu:number, imgName:string, gender:string) => {
    let encodedImgUrl = "" 
    if(jigu == 2){
        encodedImgUrl = imgName;
    }else if(jigu == 3 && imgName){
        encodedImgUrl = imgName.normalize('NFC');
    }
    // let encodedImgUrl = imgName 
    if(encodedImgUrl){
        return `https://clublions.s3.ap-northeast-2.amazonaws.com/${encodedImgUrl}`
    }else{
        if(gender == "남자"){
            return defalutMaleImg;
        }else if(gender == "여자"){
            return defalutFemaleImg;
        }else{
            return logo;
        }
    }
}

export const getYearMonth = () => {
    const today = new Date();
    const startYear = 2023;
    const startMonth = 12;
    //2023.12 월과의 차
    let monthCnt = (today.getFullYear()-startYear)*12 - startMonth + 2 + today.getMonth();
    let months: any[] = [];
    // (2024년 1월부터 시작
    let todayMonth = today.getMonth()+1;
    for(let i=0; i < monthCnt; i++){
        let tMonth = todayMonth - i;
        let tShare = Math.trunc(tMonth / 12);
        let tYear = tMonth > 0 ? today.getFullYear() : today.getFullYear() + Math.trunc(tMonth/12) -1 ;

        if(tMonth <= 0){
            tMonth = tShare*(-12) + tMonth + 12;
        }
        
        months.push({
            value: i + 1,
            label: tYear+"."+ tMonth
        }) 
    }
   
    return months;
}

export const getMonthFormat = (month : number) => {
    return month < 10 ? "0"+ month : month+"";
}

// ex 2024.1 --> 2024-01
export const changeMonthFormat = (month: string) => {
    if(month.includes('-')){
        return month;
    }
    let spMonth = month.split('.');
    return spMonth[0]+"-"+getMonthFormat(Number(spMonth[1]));
}

export const chageMoneyFormat = (money:number) => {
    let numStr = money.toString();
    // 정규 표현식을 사용하여 천 단위로 콤마를 추가
    numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numStr;
}

export const getObjFromOptionLabelInSelect = (label:string, selectOptions:Array<selectOption>) => {
    let optionObjArr: any[] = [];
    selectOptions.forEach(option =>{
        if(option.label == label){
            optionObjArr.push(option)
        }
    });

    return optionObjArr[0];
}

export const inspectionArrayHasValue = (value:string, dataArray:Array<string>) => {
    let isExist = false;
    dataArray?.forEach(data => {
        if(data == value){
            isExist = true;
        }
    })
    return isExist;
}

export const S3_URL = "https://clublions.s3.ap-northeast-2.amazonaws.com/";

export const 
getArrayToCommaString = (arr :Array<string> | undefined) => {
    let resultStr = "";
    if(arr){
        arr.forEach((each, index) => {
            if(index == arr.length-1){
                resultStr = resultStr + each;
            }else{
                resultStr = resultStr + each +", ";
            }
        })
    }else{
        resultStr = "";
    }
    return resultStr;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    })
);

export const to2DArray = (arr : Array<any>, n:number) => {
    // 배열의 길이를 구합니다.
    const len = arr.length;
    // 배열을 2차원 배열로 변환합니다.
    const result = [];
    for (let i = 0; i < len; i += n) {
      result.push(arr.slice(i, i + n));
    }
  
    return result;
}

export interface ModalProps{
    modalOpen:boolean,
    handleClose:() => void,
    uiToApply: any,
}

export const isNFD = (str:string) => {
    return str === str.normalize('NFD');
}

export const BasicModal = (props:ModalProps) => {
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.modalOpen}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.modalOpen}>
                {props.uiToApply}
            </Fade>
        </Modal>
    );
};



